import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOADING_STATES } from "../utils/constants";
import {
  getListsAPI,
  createListAPI,
  addItemAPI,
  deleteItemAPI,
  deleteListAPI,
} from "../apiService/listService";

export const getWishlists = createAsyncThunk(
  "wishlists/getWishlists",
  async (_, { rejectWithValue }) => {
    try {
      return await getListsAPI();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createWishlist = createAsyncThunk(
  "wishlists/createWishlist",
  async (data, { rejectWithValue }) => {
    try {
      return await createListAPI(data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addItem = createAsyncThunk(
  "wishlists/addItem",
  async ({ list_id, item }, { rejectWithValue }) => {
    try {
      return await addItemAPI(list_id, item).then((response) => ({
        list_id,
        item: response.data,
      }));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteItem = createAsyncThunk(
  "wishlists/deleteItem",
  async ({ list_id, itemId }, { rejectWithValue }) => {
    try {
      return await deleteItemAPI(list_id, itemId).then((response) => ({
        list_id,
        itemId,
      }));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteWishlist = createAsyncThunk(
  "wishlists/deleteWishlist",
  async (list_id, { rejectWithValue }) => {
    try {
      return await deleteListAPI(list_id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const wishlistsSlice = createSlice({
  name: "wishlists",
  initialState: {
    owned_lists: [],
    shared_lists: [],
    error: null,
    status: LOADING_STATES.IDLE,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWishlists.fulfilled, (state, action) => {
        state.owned_lists = action.payload.owned_lists ?? [];
        state.shared_lists = action.payload.shared_lists ?? [];
        state.status = LOADING_STATES.SUCCEEDED;
      })
      .addCase(getWishlists.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.error = action.payload;
      })
      .addCase(getWishlists.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
        state.error = null;
      })
      .addCase(createWishlist.fulfilled, (state, action) => {
        state.owned_lists.push(action.payload);
        state.status = LOADING_STATES.SUCCEEDED;
      })
      .addCase(createWishlist.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.error = action.payload;
      })
      .addCase(createWishlist.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
        state.error = null;
      })
      .addCase(addItem.fulfilled, (state, action) => {
        const { list_id, item } = action.payload;
        const list = state.owned_lists.find((list) => list._id === list_id);
        if (list) {
          list.items.push(item);
        } else {
          console.error("List not found for ID:", list_id);
        }
        state.status = LOADING_STATES.SUCCEEDED;
      })
      .addCase(addItem.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.error = action.payload;
      })
      .addCase(addItem.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
        state.error = null;
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        const { list_id, itemId } = action.payload;
        const list = state.owned_lists.find((list) => list._id === list_id);
        list.items = list.items.filter((item) => item._id !== itemId);
        state.status = LOADING_STATES.SUCCEEDED;
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteItem.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
        state.error = null;
      })
      .addCase(deleteWishlist.fulfilled, (state, action) => {
        const { list_id } = action.payload;
        state.owned_lists = state.owned_lists.filter(
          (list) => list._id !== list_id
        );
        state.status = LOADING_STATES.SUCCEEDED;
      })
      .addCase(deleteWishlist.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteWishlist.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
        state.error = null;
      });
  },
});

export const {} = wishlistsSlice.actions;

export default wishlistsSlice.reducer;
