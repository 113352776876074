import axios from "axios";
import ENDPOINTS from "../apiService/endpoints";

const api = axios.create({
  baseURL: ENDPOINTS.BASE_URL,
  withCredentials: true,
});

export const handleApiRequest = async (method, url, data = {}) => {
  try {
    const response = await api[method](url, data);
    return response.data;
  } catch (error) {
    const errorMessage = {
      get: "Error getting data",
      post: "Error posting data",
      put: "Error updating data",
      delete: "Error deleting data",
    };

    throw new Error(
      error.response?.data?.message || errorMessage[method] + " to " + url
    );
  }
};

const createApiCall =
  (method, path) =>
  (data = {}) =>
    handleApiRequest(method, path, data);

export default createApiCall;
