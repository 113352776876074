import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1d3557",
    },
    secondary: {
      main: "#457b9d",
    },
    error: {
      main: "#e63946",
    },
    complementary: {
      main: "#a8dadc",
    },
    accent: {
      main: "#28A745",
    },
    neutral: {
      main: "#f1faee",
    },
    danger: {
      main: "#e63946",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#556cd6",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
            color: "#333",
          },
        },
      },
    },
  },
});

export default theme;
