import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { APP_NAME } from "../utils/constants";

const Dashboard = lazy(() => import("../screens/Dashboard"));
const NewList = lazy(() => import("../screens/NewList"));
const ListDetails = lazy(() => import("../screens/ListDetails"));
const Error404 = lazy(() => import("../screens/Error404"));
const Account = lazy(() => import("../screens/Account"));
const MyLists = lazy(() => import("../screens/MyLists"));

const authRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    title: `Dashboard - ${APP_NAME}`,
  },
  {
    path: "/list/:list_id",
    component: ListDetails,
    title: `List Details - ${APP_NAME}`,
  },
  { path: "/new-list", component: NewList, title: `New List - ${APP_NAME}` },
  { path: "/account", component: Account, title: `Account - ${APP_NAME}` },
  { path: "/my-lists", component: MyLists, title: `My Lists - ${APP_NAME}` },
  { path: "/404", component: Error404, title: `Error 404 - ${APP_NAME}` },
  { path: "*", element: <Navigate to="/404" replace /> },
];

export default authRoutes;
