import createApiCall from "../utils/axios";
import { API_METHODS } from "../utils/constants";
import ENDPOINTS from "./endpoints";

export const getListsAPI = createApiCall(API_METHODS.GET, ENDPOINTS.LISTS);
export const getListByIDAPI = (list_id) =>
  createApiCall(API_METHODS.GET, `${ENDPOINTS.LISTS}/${list_id}`)();
export const createListAPI = createApiCall(API_METHODS.POST, ENDPOINTS.LISTS);
export const updateListAPI = (list) =>
  createApiCall(API_METHODS.PUT, `${ENDPOINTS.LISTS}/${list._id}`)(list);
export const deleteListAPI = (list_id) =>
  createApiCall(API_METHODS.DELETE, `${ENDPOINTS.LISTS}/${list_id}`)();
export const getItemsAPI = (list_id) =>
  createApiCall(API_METHODS.GET, ENDPOINTS.LIST_ITEMS(list_id))();
export const addItemAPI = (list_id, item) =>
  createApiCall(API_METHODS.POST, ENDPOINTS.LIST_ITEMS(list_id))(item);
export const updateItemAPI = (list_id, item) =>
  createApiCall(
    API_METHODS.PUT,
    `${ENDPOINTS.LIST_ITEMS(list_id)}/${item._id}`
  )(item);
export const deleteItemAPI = (list_id, itemId) =>
  createApiCall(
    API_METHODS.DELETE,
    `${ENDPOINTS.LIST_ITEMS(list_id)}/${itemId}`
  )();
export const shareListAPI = (list_id, data) =>
  createApiCall(API_METHODS.POST, ENDPOINTS.SHARE_LIST(list_id))(data);
