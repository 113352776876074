import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginWithCookies } from "./redux/authSlice";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Box from "@mui/material/Box";
import Loading from "./components/Loading";

import authRoutes from "./routes/authRoutes";
import guestRoutes from "./routes/guestRoutes";

function AppRoutes({ isLoggedIn, authRoutes, guestRoutes }) {
  const location = useLocation();
  const routes = isLoggedIn ? authRoutes : guestRoutes;

  useEffect(() => {
    const currentRoute = routes.find(
      (route) => route.path === location.pathname
    );
    if (currentRoute && currentRoute.title) {
      document.title = currentRoute.title;
    }
  }, [location, routes]);

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={route.element || <route.component />}
        />
      ))}
    </Routes>
  );
}

function App() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginWithCookies());
  }, [dispatch]);

  return (
    <Router>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {isLoggedIn && <Header />}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Suspense fallback={<Loading />}>
            <AppRoutes
              isLoggedIn={isLoggedIn}
              authRoutes={authRoutes}
              guestRoutes={guestRoutes}
            />
          </Suspense>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
