import createApiCall from "../utils/axios";
import { API_METHODS } from "../utils/constants";
import ENDPOINTS from "./endpoints";

export const loginAPI = createApiCall(API_METHODS.POST, ENDPOINTS.LOGIN);
export const loginWithCookiesAPI = createApiCall(
  API_METHODS.GET,
  ENDPOINTS.LOGIN
);
export const logoutAPI = createApiCall(API_METHODS.POST, ENDPOINTS.LOGOUT);
export const signUpAPI = createApiCall(API_METHODS.POST, ENDPOINTS.SIGNUP);
