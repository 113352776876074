import createApiCall from "../utils/axios";
import { API_METHODS } from "../utils/constants";
import ENDPOINTS from "./endpoints";

export const updateUserAPI = (data) =>
  createApiCall(API_METHODS.POST, ENDPOINTS.USER_PROFILE)(data);
export const fetchUserAPI = createApiCall(
  API_METHODS.GET,
  ENDPOINTS.USER_PROFILE
);
