import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { APP_NAME } from "../utils/constants";

const Login = lazy(() => import("../screens/Login"));
const Signup = lazy(() => import("../screens/Signup"));

const guestRoutes = [
  { path: "/login", component: Login, title: `Login - ${APP_NAME}` },
  { path: "/signup", component: Signup, title: `Signup - ${APP_NAME}` },
  { path: "*", element: <Navigate to="/login" replace /> },
];

export default guestRoutes;
