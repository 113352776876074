export const LOGIN = "/auth/login";
export const LOGOUT = "/auth/logout";
export const SIGNUP = "/auth/signup";
export const LISTS = "/lists";
export const LIST_ITEMS = (list_id) => `/lists/${list_id}/items`;
export const SHARE_LIST = (list_id) => `/lists/${list_id}/share`;
export const USER_PROFILE = "/users/profile";
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:8080/api/v1";

const ENDPOINTS = {
  LOGIN,
  LOGOUT,
  SIGNUP,
  LISTS,
  LIST_ITEMS,
  SHARE_LIST,
  USER_PROFILE,
  BASE_URL,
};

export default ENDPOINTS;
