import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { APP_NAME } from "../utils/constants";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 1,
          }}
        >
          <IconButton
            component="a"
            href="https://www.linkedin.com/in/tannerhelton"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://forms.gle/2457hYRZdYP46YWT6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FeedbackIcon />
          </IconButton>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} {APP_NAME}. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
