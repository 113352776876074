import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOADING_STATES } from "../utils/constants";

import {
  loginAPI,
  loginWithCookiesAPI,
  logoutAPI,
  signUpAPI,
} from "../apiService/authService";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const res = await loginAPI({ email, password });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const loginWithCookies = createAsyncThunk(
  "auth/loginWithCookies",
  async (_, { rejectWithValue }) => {
    try {
      const res = await loginWithCookiesAPI();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const res = await logoutAPI();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    try {
      const res = await signUpAPI(data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    error: null,
    status: LOADING_STATES.IDLE,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
      })
      .addCase(login.fulfilled, (state) => {
        state.status = LOADING_STATES.SUCCEEDED;
        state.isLoggedIn = true;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.isLoggedIn = false;
        state.error = action.payload;
      })
      .addCase(loginWithCookies.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
      })
      .addCase(loginWithCookies.fulfilled, (state) => {
        state.status = LOADING_STATES.SUCCEEDED;
        state.isLoggedIn = true;
        state.error = null;
      })
      .addCase(loginWithCookies.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.isLoggedIn = false;
        state.error = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = LOADING_STATES.SUCCEEDED;
        state.isLoggedIn = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.error = action.payload;
      })
      .addCase(signUp.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
      })
      .addCase(signUp.fulfilled, (state) => {
        state.status = LOADING_STATES.SUCCEEDED;
        state.isLoggedIn = true;
        state.error = null;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.status = LOADING_STATES.FAILED;
        state.error = action.payload;
      });
  },
});

export const { setError, clearError, setIsLoggedIn } = authSlice.actions;

export default authSlice.reducer;
