export const APP_NAME = "Wish Whisper";
export const APP_DESCRIPTION = "A place to share your wishes with the world";
export const LOADING_STATES = {
  IDLE: 0,
  LOADING: 1,
  SUCCEEDED: 2,
  FAILED: 3,
};
export const API_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};
