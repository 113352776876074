import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login, loginWithCookies, logout, signUp } from "./authSlice";
import { LOADING_STATES } from "../utils/constants";

import { updateUserAPI, fetchUserAPI } from "../apiService/userService";

export const updateUserDetails = createAsyncThunk(
  "user/updateUserDetails",
  async (data, { rejectWithValue }) => {
    try {
      const tmp = await updateUserAPI(data);
      return tmp.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, { rejectWithValue }) => {
    try {
      return await fetchUserAPI();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  _id: "",
  status: LOADING_STATES.IDLE,
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      Object.assign(state, action.payload);
    },
    clearUser: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
      state.status = LOADING_STATES.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.status = LOADING_STATES.SUCCEEDED;
        state.error = null;
      })
      .addCase(loginWithCookies.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.status = LOADING_STATES.SUCCEEDED;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        Object.keys(initialState).forEach((key) => {
          state[key] = initialState[key];
        });
        state.status = LOADING_STATES.SUCCEEDED;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.status = LOADING_STATES.SUCCEEDED;
        state.error = null;
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.status = LOADING_STATES.SUCCEEDED;
        state.error = null;
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.status = LOADING_STATES.FAILED;
      })
      .addCase(updateUserDetails.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        Object.assign(state, action.payload.data);
        state.status = LOADING_STATES.SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.error = action.payload;
        state.status = LOADING_STATES.FAILED;
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
      });
  },
});

export const { updateProfile, setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
